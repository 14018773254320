#footer {
  background-color: #468189;
  color: #fdf8f3;
  font-size: 12px;
  display: flex;
  justify-content: center;
}

#footerbox {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

#footer h5 {
  font-size: 100%;
  font-family: "hubballi";
}

#email-form h5 {
  font-size: inherit;
  font-family: "hubballi";
}

#email-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#social-media {
  display: flex;
  flex-direction: column;
}

#footerbox h5 {
  font-family: "Hubballi";
  font-size: 25px;
  margin-top: 45px;
  margin-bottom: 20px;
}

#emailinput button {
  margin-top: 4%;
  width: 40%;
  border: none;
  border-radius: 20px;
  color: #fff4f1;
  background-color: #d5896f;
  padding-top: 5px;
  padding-bottom: 5px;
}

#emailinput input {
  border-radius: 50px;
  border: none;
  padding: 5px;
}

#footerbox {
  margin-bottom: 30px;
}

@media (width < 748px) {
  #footerbox {
    display: flex;
    flex-direction: column;
  }
  #contact,
  #email-form {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  #social-media {
    display: flex;
    flex-direction: column;
  }

  #social-media p {
    margin-top: 0%;
    margin-bottom: 0%;
  }

  #emailbtn {
    display: flex;
  }
  #emailbtn button {
    width: 157.69px;
  }
  #emailinput input {
    width: 147.69px;
  }

}

/* #contact,
#email-form {
  width: 75%;
  align-self: center;
}

#social-media {
  display: flex;
  flex-direction: column;
}

#social-media p {
  margin-top: 0%;
  margin-bottom: 0%;
}

#emailbtn {
  display: flex;
  justify-content: center;
}
#emailbtn button {
  width: 130%;
} */


#social-media img {
  width: 15px;
  height: 15px;
}

#social-media {
  display: flex;
}

#social-mediaOne, #social-mediaTwo, #social-mediaThree {
  display: flex;
  gap: 2px;
}

#social-mediaOne img {
  display: flex;
  align-self: center;
}

#social-mediaThree img {
  display: flex;
  align-self: center;
}

#social-mediaTwo img {
  display: flex;
  align-self: center;
}



