#checkouttitle {
    text-align: center;
}

#checkoutdiv p {
    text-align: center;
}

#checkoutdiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
    height: 1080px;
}

#checkoutbtn {
    text-align: center
}

#checkoutpage {
    display: flex;
    flex-direction: column;
    margin: auto;
}

#checkoutpage {
    text-align: center;
    margin: auto;
    width: 80%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

/* #buttons-container div:nth-child(1) {
    display: none;
} */

div #paypal-button-container div:nth-child(0){
    display:none;
}
div #paypal-button-container div:nth-child(1){
    display:none;
}

div #buttons-container {
    display: none;
}