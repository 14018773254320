#quoteblock {
  background-color: #efecca;
  font-family: "Inter";
  display: flex;
  justify-content: space-around;
  border: none;
  box-shadow: inset 0px 0px 100px -0.01px #fdf8f3;
  padding: 100px;
  padding-left: 20px;
  padding-right: 20px;
  #endquote img {
    justify-content: flex-end;
    transform: rotate(180deg);
  }
  #endquote {
    display: flex;
    justify-content: flex-end;
  }
}

@media (width < 1130px) {
  #quoteblock {
    flex-direction: column;
  }
  #quote1,
  #quote2 {
    align-self: center;
  }
}
