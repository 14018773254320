#root {
  background-color: #fdf8f3;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#content {
  width: 75%;
  align-self: center;
}

#pagename {
  text-align: center;
  font-family: Hubballi;
  color: 000000;
}

#banner {
  background-color: #468189;
  color: #fdf8f3;
  display: flex;
  justify-content: center;
  font-size: 12px;
}

#searchbar {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

#searchbarbox {
  display: flex;
  justify-content: space-between;
  flex-basis: 100px;
  background-color: #ffffff;
  padding: 5px;
  border-radius: 50px;
  flex-grow: 1;
}

input[type="search"] {
  width: 94%;
  border: none;
}

#cartbtn {
  background-color: #efecca;
  border-radius: 40px;
  padding-right: 20px;
  padding-left: 20px;
  border: none;
  margin-left: 30px;
}

ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  list-style: none;
  padding-left: 0%;
  padding-top: 1%;
  padding-bottom: 1%;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  color: #000000;
}

button {
  font-family: "Inter", sans-serif;
}

li > button {
  border-style: none;
  background-color: inherit;
  color: #000000;
}

#holidaydisc h1 {
  font-family: "Dancing Script";
}
#holidaytext p {
  font-weight: 500;
}

@media (width <= 1920px) {
  #holidaypromo {
    display: flex;
    justify-content: space-between;
    margin-top: 3.5%;
  }

  #holidaydisc {
    width: 50%;
    font-size: 28.8px;
  }

  #holidayimg {
    width: 40%;
  }

  #holidayimg img {
    width: 100%;
    height: 490px;
    object-fit: cover;
  }

  #holidaybtn {
    display: flex;
    justify-content: center;
    margin-top: 7%;
  }

  #explorebtn {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 40px;
    padding-right: 40px;
    border: none;
    border-radius: 30px;
    color: #fff4f1;
    background-color: #d5896f;
  }

  #explorebtn {
    font-size: 80%;
  }
}

@media (width <= 1520px) {
  #holidaydisc {
    font-size: 22px;
  }

  #holidayimg img {
    height: 390px;
  }
}

@media (width <= 1232px) {
  #holidaydisc {
    font-size: 18px;
  }

  #holidayimg img {
    height: 320px;
  }
}

@media (width <= 950px) {
  #holidaydisc {
    font-size: 14px;
  }

  #holidayimg img {
    height: 240px;
  }

  #cartcontent {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    width: 80%;
  }

  #cartcontent > div{
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    width:100%;
    justify-content: space-between;
  }

  #cartcontent > div > p {
    width: 80px;
    display: flex;
    align-self: center;
  }
  #cartcontent img {
    width: 20px;
    height: 20px;
    display: flex;
    align-self: center;
  }

  #cartcontent button {
    background-color: inherit;
    display: flex;
    align-self: center;
    border: none;
  }
  #cartcontent > button:hover {
    color: #468189;
  }
}

@media (width <= 750px) {
  #holidaydisc {
    font-size: 12px;
  }

  #holidayimg img {
    height: 190px;
  }
  #cartcontent > div > p {
    width: 80px;
  }
  
}

@media (width <= 642px) {
  #holidaypromo {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
  }
  #holidayimg {
    width: 100%;
  }
  #holidayimg img {
    height: 400px;
    position: absolute;
  }

  #holidaydisc {
    background-color: rgba(253, 248, 243, 0.6);
    width: 100%;
    z-index: 9;
    display: flex;
    flex-direction: column;
    margin-top: 217px;
    height: 183px;

    #holidaytext {
      width: 80%;
      align-self: center;
      font-size: 90%;
      color: #000000;
    }
  }
}

#cartcontent button {
  background-color: inherit;

  border: none;
}
#cartcontent > button:hover {
  color: #468189;
}

button:hover {
  color: #468189;
}

#product-controls button:hover{
  background-color: #468189;
}

#checkoutbtn {
  display: flex;
  justify-content: center;
  color: #468189;
  text-decoration: none;
}

#checkoutbtn a {
  color: #468189;
  border: none;
  margin: none;
  padding: none;
  text-decoration: none;
}

nav {
  padding: 0px;
  text-decoration: none;
  margin-top: -20px;
  font-size: 20px;
}