#productpagediv {
    width: 75%;
    margin: auto;
}

#productPage {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: rgb(95, 158, 160, 0.1);
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

#productpagename {
    display: flex;
    width: 50%;
    flex-direction: column;
    font-size: 20px;
}


#productpagecontrols button {
    height: 50px;
    width: 150px;
    border-radius: 30px;
    background-color: #d5896f;
    color: #fff4f1;
    font-size: 100%;
    text-align: center;
    border: none;
    margin-bottom: 30px;
}

#productpagecontrols button:hover {
    background-color: cadetblue;
}

#productpagecontrols p {
    margin: 0%;
}

#productpagecontrols {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 5%;
  }

  #categ {
    font-size: 10px;
    font-style: italic;
  }