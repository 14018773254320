#searchbtn {
  border: none;
  background-color: inherit;
}

@media (width <= 1920px) {
  #pagename h1 {
    font-size: 400%;
  }
}

@media (width <= 1520px) {
  #pagename h1 {
    font-size: 300%;
  }
}

@media (width <= 1232px) {
  #pagename h1 {
    font-size: 250%;
  }
}

@media (width <= 950px) {
  #pagename h1 {
    font-size: 190%;
  }
}

@media (width <= 750px) {
  #pagename h1 {
    font-size: 120%;
  }
}

@media (width <= 642px) {
  #pagename h1 {
    font-size: 200%;
  }
  #searchbarbox {
    margin-bottom: 10px;
  }

  #cartbtn {
    width: 100%;
    margin-left: 0px;
  }

  #cartbtnbox {
    width: 100%;
  }
  #navbar ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  #navbar button {
    padding: 0%;
  }
  li {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: 70px;
    width: 60px;
  }

  li button {
    font-size: 10px;
  }
}

@media (width < 574px) {
  #productrow {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  #productblock {
    width: 87%;
    display: flex;
    align-self: center;
    flex-direction: column;
  }
  #productimg img {
    width: 100%;
    height: 200px;
  }
}

#backbtn {
  padding: 0px;
  margin-bottom: 15px;
}