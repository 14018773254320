#productrowtitle {
  font-family: "Hubballi";
  display: flex;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 50px;
  font-size: 50px;
}

#productrow {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 60px;
}

#productimg {
  width: 100%;
}

#productimg img {
  width: 430px;
  height: 290px;
}

#productdisc {
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: center;
}

#productdisc p {
  padding: 5px;
}

#product-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
}

#product-controls p {
  margin: 0%;
}

#product-controls button {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #d5896f;
  color: #fff4f1;
  font-size: 140%;
  text-align: center;
  border: none;
  margin-bottom: 30px;
}

@media (width <= 1720px) {
  #productblock {
    margin-top: 10%;
  }
}

#backbtn {
  border: none;
  background-color: inherit;
  font-size: 15px;
  display: flex;
  margin-bottom: 100px;
}

#backbtn:hover {
  color: rgba(71, 173, 210, 0.796);
}

button:hover {
  color: rgba(71, 173, 210, 0.796);
}

#shoppingcart {
  text-align: center;
  width: 100%;
  margin: 10px 0px 10px 0px;
  border-radius: 40px;
  align-self: flex-start;
  top: 300px;
  left: 60%;
  background-color: rgb(239, 236, 202);
  height: 400px;
  display: none;
}

#shoppingcart > h4 {
  padding-top: 30px;
}

#cartcontent {
  display: flex;
  justify-content: space-evenly;
}

#moreholidayinfo {
  display: flex;
  justify-content: center;  
}